import { createTheme } from "@mui/material/styles";

import colors from "./colors";
import components from "./components";

import Logo from "./logo";

export default createTheme({
  colors: colors,
  customComponents: components,
  configuration: {
    passwordRecoveryEnabled: true,
    socialAuthEnabled: false,
    gloryToUkraine: false,
    registrationMode: 'ids'
  },
  variables: {
    providerName: "BlazingCDN",
    supportEmail: "support@blazingcdn.com",
    termsAndConditionsList: [
      {
        title: "Terms of Service",
        url: "https://blazingcdn.com/terms"
      },
      {
        title: "Privacy Policy",
        url: "https://blazingcdn.com/privacy"
      },
      {
        title: "GDPR",
        url: "https://blazingcdn.com/cdn-gdpr"
      }
    ],
    registrationProductOptions: [
      "CDN",
      "Other"
    ]
  },
  images: {
    Logo: Logo
  },
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: colors.pink,
      main: colors.pink,
      dark: colors.pink,
      contrastText: "#fff"
    },
    secondary: {
      light: colors.black9,
      main: colors.pink,
      dark: colors.pink,
      contrastText: "#fff"
    },
    current: {
      light: colors.black9,
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000"
    },
    warning: {
      light: colors.pink,
      main: colors.pink,
      dark: colors.pink,
      contrastText: "#fff"
    }
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: "none"
      },
      styleOverrides: {
        root: {
          color: colors.pink
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 14,
          letterSpacing: "0.5px",
          lineHeight: "18px",
          padding: "8px 18px",
          boxShadow: "none !important",
          fontFamily: "Roboto, sans-serif",
          fontWeight: 500,
          whiteSpace: "nowrap",
          height: 36,
          "&:disabled": {
            color: colors.black27
          }
        },
        contained: {
          boxShadow: "none",
          "& *[class*='ico-']": {
            margin: "2px 9px 0 -5px",
            "&:before": {
              color: colors.white
            }
          },
          "&:disabled": {
            borderColor: colors.black9,
            "& .ico-plus_fab:before": {
              color: colors.black27
            }
          }
        },
        outlinedPrimary: {
          boxShadow: "none",
          borderColor: colors.black18,
          color: colors.pink,
          padding: "7px 16px",
          "& span:before": {
            color: colors.pink
          },
          "&:disabled": {
            borderColor: colors.black18,
            "& .ico-plus:before": {
              color: colors.black27
            }
          }
        },
        text: {
          padding: "8px 9px",
          "& + button": {
            marginLeft: 9
          },
          "&.btnStyleText": {
            textTransform: "none",
            padding: "0 9px",
            background: "none !important",
            fontSize: 16,
            letterSpacing: 0
          },
          "&.btnOnlyText": {
            textTransform: "none",
            padding: "0",
            background: "none !important",
            letterSpacing: 0
          },
          '& span[class*="ico-"]': {
            "&:before": {
              fontSize: 18,
              color: "inherit"
            }
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          paddingRight: 6,
          border: 0,
          paddingLeft: 18,
          boxSizing: "border-box",
          "& .ico-warning_info:before": {
            fontSize: 18
          },
          "&:hover .btn-edit": {
            visibility: "visible"
          },
          "& .ico-check_line:before, & .ico-edit::before": {
            color: colors.black36
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.black9
          },
          ".hastext & .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.red54,
            borderWidth: 2
          },
          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.black18
            },
            "& .MuiOutlinedInput-input": {
              color: colors.pink,
              "&:-webkit-autofill": {
                WebkitTextFillColor: colors.pink,
              },
            }
          },
          "&.Mui-error, &.Mui-error:hover": {
            "& .ico-error_status:before": {
              color: colors.red90
            },
            "& input": {
              color: colors.pink,
              "&:-webkit-autofill": {
                WebkitTextFillColor: colors.pink,
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.red54,
              borderWidth: 2
            },
            "& .ico-warning_info:before": {
              color: colors.red90
            }
          },
          "&.Mui-error.Mui-error input": {
            color: colors.black54,
            "&:-webkit-autofill": {
              WebkitTextFillColor: colors.black54,
            },
          },
          ".warning &, .warning &:hover": {
            "& .ico-warning_info:before": {
              color: colors.yellow90
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.yellow54,
              borderWidth: 2
            }
          },
          "& .button-reset-default .ico-error_status:before": {
            color: colors.pink
          },
          "&.Mui-focused, &.Mui-focused:hover": {
            "& button.btn-edit": {
              visibility: "hidden"
            },
            "& .btn-complete": {
              visibility: "visible"
            },
            "& .button-reset-default": {
              visibility: "visible",
              color: colors.pink
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.red54
            },
            "& .MuiOutlinedInput-input": {
              color: colors.pink,
              "&:-webkit-autofill": {
                WebkitTextFillColor: colors.pink,
              },
            },
            "& .ico-calendar:before": {
              color: colors.pink
            }
          },
          "&.Mui-error.Mui-error.Mui-focused input": {
            color: colors.pink,
            "&:-webkit-autofill": {
              WebkitTextFillColor: colors.pink,
            },
          },
          "&.Mui-disabled, &.Mui-disabled:hover": {
            "& button.btn-edit": {
              visibility: "hidden"
            },
            "& input": {
              color: colors.black18,
              "&:-webkit-autofill": {
                WebkitTextFillColor: colors.black18,
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.black5
            }
          },
          "& .MuiOutlinedInput-input": {
            color: colors.black54,
            opacity: 1,
            border: 0,
            paddingTop: 0,
            paddingBottom: 0,
            boxSizing: "border-box",
            height: 54,
            fontSize: 16,
            fontWeight: 500,
            lineHeight: "18px",
            textOverflow: "ellipsis",
            "&:-webkit-autofill": {
              WebkitTextFillColor: colors.black54,
            },
            "&::placeholder": {
              color: colors.black36,
              opacity: 1
            }
          },
          "&.MuiInputBase-adornedEnd": {
            paddingRight: "14px"
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '9px',
          "&:hover": {
            backgroundColor: colors.red9,
            '& *[class*="ico-"]:before': {
              color: colors.white
            }
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "transparent",
          height: "100%",
          border: `1px solid ${colors.black9}`,
          borderBottom: "none",
          boxSizing: "border-box",
          borderRadius: "3px 3px 0 0",
          "&:before": {
            content: '""',
            position: "absolute",
            width: "100%",
            height: 1,
            display: "block",
            backgroundColor: "#fff",
            left: -2,
            bottom: 0,
            borderLeft: `2px solid ${colors.black9}`,
            borderRight: `2px solid ${colors.black9}`
          }
        },
        root: {
          minHeight: 45,
          height: 45,
          overflow: "visible",
          position: "relative",
          "&:after": {
            content: '""',
            display: "block",
            position: "absolute",
            width: "1000%",
            height: 1,
            backgroundColor: colors.black9,
            left: "100%",
            bottom: 0
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            width: "1000%",
            height: 1,
            backgroundColor: colors.black9,
            right: "100%",
            bottom: 0
          },
          "& $scroller": {
            overflow: "visible"
          }
        },
        scroller: {
          overflow: "visible",
          "& > div:first-child": {
            boxShadow: `inset 0 -1px 0 0 ${colors.black9}`
          }
        },
        flexContainer: {
          height: "100%",
          display: "block"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "initial",
          margin: 0,
          height: 44,
          minWidth: "50px !important",
          minHeight: 44,
          padding: "6px 18px",
          fontWeight: 500,
          fontSize: 16,
          fontFamily: "Roboto",
          letterSpacing: 0,
          flexDirection: "initial",
          "& *[class*='ico-']:before": {
            color: colors.black36
          },
          '&:hover, &:hover[class*="ico-"]:before': {
            color: colors.pink,
            opacity: 1
          },
          '&.Mui-selected, &.Mui-selected[class*="ico-"]:before': {
            color: colors.pink
          }
        },
        selected: {},
        textColorInherit: {
          color: colors.black54,
          opacity: 1
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&.Mui-error input": {
            color: colors.red90
          }
        },
        error: {},
        underline: {
          "&:after": {
            borderColor: colors.red54
          }
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionStart: {
          "&.outlinedInput": {
            "& + input": {
              paddingLeft: 0
            }
          },
          '& *[class*="ico-"]': {
            width: 18,
            height: 18,
            display: "inline-block",
            marginRight: 2,
            top: -2,
            left: 0,
            position: "relative",
            "&:before": {
              width: 18,
              height: 18,
              fontSize: 18,
              color: colors.pink
            }
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: -9,
          marginRight: 27
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-outlined": {
            fontWeight: 500
          },
          "& .MuiInputLabel-marginDense": {
            fontWeight: 400,
            fontSize: 14
          },
          "&.picker .MuiOutlinedInput-input": {
            height: 36
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        body2: {
          fontSize: 16,
          letterSpacing: 0,
          lineHeight: "normal",
          fontWeight: "initial"
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          "&.MuiInputLabel-shrink": {
            fontWeight: "500 !important",
            fontSize: `16px !important`
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: colors.black36,
          letterSpacing: "inherit",
          "&.Mui-focused": {
            color: colors.pink
          },
          "&.Mui-error": {
            color: colors.red90
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: colors.black36,
          fontSize: 12,
          fontWeight: "normal",
          fontStyle: "normal",
          lineHeight: "13px",
          letterSpacing: 0,
          margin: "6px 14px -3px",
          "&:first-letter": {
            textTransform: "uppercase"
          },
          "&.Mui-error": {
            color: `${colors.red90}`
          }
        }
      }
    }
  },
});
