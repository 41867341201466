import React, {useState} from 'react';
import styles from "./styles";
import Link from "@mui/material/Link";
import SliderForm from "../Shared/Slider/SliderForm";
import WebsaUITheme from '../Shared/WebsaUITheme';
import ThemeProvider from '../Shared/ThemeProvider';

import RailsForm from '../Shared/RailsForm';
import RailsRoutes from '../Shared/RailsRoutes';

import OutlinedInput from "../Shared/OutlinedInput"
import Button from "@mui/material/Button"
import DividerSocialBtn from "../Shared/DividerSocialBtn/DividerSocialBtn";
import CheckboxLabel from "../Shared/CheckboxLabel/CheckboxLabel";
import Copyright from "../Shared/Copyright/Copyright";
import Banner from "../Shared/Banner";

import { tss } from 'tss-react';
import WebsaSelect from "../Shared/WebsaSelect";
import _ from "lodash";

import countryList from 'react-select-country-list';

import ReCAPTCHA from "react-google-recaptcha";
import {Box} from "@mui/material";

export default function RegistrationForm(props) {
  const theme = ThemeProvider(props.theme);
  const { classes, cx } = tss.create(styles(theme))();
  const [params, setParams] = useState(props.form);
  const [errors, setErrors] = useState(props.form.errors);
  const error = props.flash.alert;

  const countryOptions = countryList().getData().filter(item => item.value !== 'RU' && item.value !== 'BY');

  const productOptions = theme.variables.registrationProductOptions.map(item => ({ value: item, label: item }));

  const recaptchaKey = props.recaptcha_key;

  const initialStep = (errors) => {
    if (Object.keys(errors).length === 0 || errors.first_name || errors.last_name || errors.email) {
      return 1;
    } else {
      return 2;
    }
  }

  const [step, setStep] = useState(() => initialStep(props.form.errors));

  const stepClass = (stepNumber) => {
    return step === stepNumber ? classes.stepActive : classes.stepHidden;
  }

  const handleChange = param => val => {
    const allParams = {...params}
    setParams({ ...allParams, [param]: val });
    setErrors({ ...errors, [param]: null });
  };

  const handleFirstStepSubmit = () => {
    let errors = {};

    if (!params.first_name) {
      errors.first_name = "First name can't be blank";
    }

    if (!params.last_name) {
      errors.last_name = "Last name can't be blank";
    }

    if (!params.email) {
      errors.email = "Email can't be blank";
    }

    if (errors.first_name || errors.last_name || errors.email) {
      setErrors(errors);
      return false;
    }

    setStep(2);
    return true;
  }

  const formRef = React.useRef();
  const recaptchaRef = React.useRef();

  const sendRecaptchaRequest = (e) => {
    e.preventDefault(); // form will be submitted by recaptcha callback

    recaptchaRef.current.execute();
  }

  const handleRecaptcha = (value) => {
    formRef.current.submit();
  }

  const formAttrs = {
    action: RailsRoutes.registration_path(),
    method: 'post',
    className: cx(classes.tagForm, 'long'),
    ref: formRef,
    onSubmit: sendRecaptchaRequest
  }

  const termLink = (item) => {
    return <Link rel="stylesheet" href={item.url} target="_blank" key={item.title}>{item.title}</Link>
  }

  const oxfordTermLinks = (linksArray) => {
    if (linksArray.length === 1) {
      return termLink(linksArray[0]);
    } else if (linksArray.length === 2) {
      return [termLink(linksArray[0]), ' and ', termLink(linksArray[1])];
    } else {
      const links = linksArray.map((item, index) => {
        if (index === 0) {
          return [termLink(item)];
        } else if (index === linksArray.length - 1) {
          return [' and ', termLink(item)];
        } else {
          return [', ', termLink(item)];
        }
      }).flat();
      return links.flat();
    }
  }

  const terms_label = (
    <span>
      I agree with {oxfordTermLinks(theme.variables.termsAndConditionsList)}.
    </span>
  )

  return (
    <WebsaUITheme theme={props.theme}>
      <div className={classes.root}>
        <div className={classes.slider}>
          <SliderForm/>
        </div>
        <div className={classes.form}>
          <div className={classes.head}>
            <p>
              Already have an account? <Link rel="stylesheet" href={RailsRoutes.new_user_session_path()}>Sign in</Link>
            </p>
          </div>
          <RailsForm attrs={formAttrs}>
            <div className={classes.root}>
              <div className={classes.regForm}>
                <span className={classes.stepIndex}>Step {step} of 2</span>
                <span className={classes.stepTitle}>Sign-up request</span>

                {error && (
                  <span className={classes.errorBlockBig}>
                    {error}
                  </span>
                )}

                <div className={classes.banner}>
                  <div className={classes.bannerInner}>
                    <span className="ico-closed" />

                    <span className={classes.bannerSeparator}/>

                    <div className={classes.bannerColumn}>
                      <div className={classes.bannerTitle}>
                        Dear Client,
                        <br/>
                        To get acquainted with your project and cater to your business needs more effectively, we
                        have suspended self-registration and will personally guide you through the process.
                      </div>

                      <div className={classes.bannerMessage}>
                        To sign up, fill out the registration request below. We'll soon be in touch to finalize your
                        registration and provide further guidance.
                      </div>
                    </div>
                  </div>
                </div>

                <div className={stepClass(1)}>
                  <div className={classes.inputBlockTitle}>Tell us about yourself:</div>
                  <OutlinedInput
                    customClass={classes.textField}
                    name="form[first_name]"
                    placeholder="First name"
                    value={params.first_name}
                    onInputComplete={handleChange("first_name")}
                    error={errors.first_name}
                    label="First name"
                    isUncontroled="isUncontroled"
                    noAdditionalBtn
                    autoComplete="given-name"/>
                  <OutlinedInput
                    customClass={classes.textField}
                    name="form[last_name]"
                    placeholder="Last name"
                    value={params.last_name}
                    onInputComplete={handleChange("last_name")}
                    error={errors.last_name}
                    label="Last name"
                    isUncontroled="isUncontroled"
                    noAdditionalBtn
                    autoComplete="family-name"/>
                  <OutlinedInput
                    customClass={classes.textField}
                    name="form[email]"
                    placeholder="Email"
                    value={params.email}
                    onInputComplete={handleChange("email")}
                    error={errors.email}
                    label="Email"
                    isUncontroled="isUncontroled"
                    noAdditionalBtn
                    autoComplete="email"/>
                  <div className={classes.inputBlockTitle}>What's your company name? (optional)</div>
                  <OutlinedInput
                    customClass={classes.textField}
                    name="form[company]"
                    placeholder="Company name"
                    value={params.company}
                    onChange={handleChange("company")}
                    error={errors.company}
                    label="Company name"
                    isUncontroled
                    noAdditionalBtn
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.fullWidth}
                    onClick={handleFirstStepSubmit}>
                    Next step
                  </Button>
                </div>
                <div className={stepClass(2)}>
                  <div className={classes.inputBlockTitle}>What services are you looking for?</div>
                  <WebsaSelect
                    name="form[products][]"
                    width={540}
                    menuHeight={196}
                    options={productOptions}
                    placeholder="Choose the services you are interested in..."
                    isSearchable
                    value={_.find(productOptions, {value: params.products})}
                    onChange={handleChange("products")}
                  />
                  <div className={classes.inputBlockTitle}>Where are you from?</div>
                  <WebsaSelect
                    name="form[country_code]"
                    width={540}
                    menuHeight={196}
                    options={countryOptions}
                    placeholder="Please, specify your country..."
                    isSearchable
                    value={_.find(countryOptions, {value: params.country_code})}
                    onChange={handleChange("country_code")}
                  />
                  <span className={classes.signature}>Your country selection will affect product prices.</span>
                  <div className={classes.inputBlockTitle}>Some additional information (optional):</div>
                  <OutlinedInput
                    customClass={classes.textField}
                    name="form[phone]"
                    placeholder="Phone"
                    value={params.phone}
                    onChange={handleChange("phone")}
                    error={errors.phone}
                    label="Phone"
                    isUncontroled
                    noAdditionalBtn
                  />
                  <OutlinedInput
                    customClass={classes.textField}
                    name="form[website]"
                    placeholder="Website"
                    value={params.website}
                    onChange={handleChange("website")}
                    error={errors.website}
                    label="Website"
                    isUncontroled
                    noAdditionalBtn
                  />
                  <OutlinedInput
                    customClass={classes.textField}
                    name="form[note]"
                    placeholder="You can provide additional details about your request if you wish..."
                    value={params.note}
                    onChange={handleChange("note")}
                    error={errors.note}
                    rows={4}
                    isUncontroled
                    noAdditionalBtn
                    multiline
                  />
                  <CheckboxLabel
                    checked={params.terms_and_conditions || false}
                    name="form[terms_and_conditions]"
                    label={terms_label}
                    value="1"
                    onChange={handleChange("terms_and_conditions")}/>
                  {errors.terms_and_conditions && (
                    <span className={classes.errorBlock}>
                      {errors.terms_and_conditions}
                    </span>
                  )}

                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={recaptchaKey}
                    size="invisible"
                    onChange={handleRecaptcha}
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.fullWidth}>
                    Send signup request
                  </Button>

                  <Button
                    variant="text"
                    color="primary"
                    className={classes.fullWidth}
                    onClick={() => setStep(1)}>
                    <span className="ico-back"/>
                    Back
                  </Button>
                </div>
              </div>
            </div>
          </RailsForm>
          <Copyright/>
        </div>
      </div>
    </WebsaUITheme>
  );
}
