import { Box, Button } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import { useTheme } from "@mui/material";

import { tss } from 'tss-react';

export default function Banner({ iconName, title, message, color, onBtnClick, btnText, ...rest }) {
  const theme = useTheme();
  const { classes } = tss.create(theme.customComponents.banner.styles)();

  return (
    <Box {...rest} className={classes.banner} data-testid="banner">
      <div className={classes.bannerInner}>
        <span className={iconName} />

        <span className={classes.separator}/>

        <div className={classes.column}>
          {title && <div className={classes.title}>{title}</div>}

          <div className={classes.message}>{message}</div>
        </div>
      </div>

      {typeof onBtnClick === "function" && (
        <div className={classes.btnHolder}>
          <Button color="primary" data-testid="banner-button" onClick={onBtnClick}>
            {btnText}
          </Button>
        </div>
      )}
    </Box>
  );
}


Banner.propTypes = {
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string,
  message: PropTypes.node.isRequired,
  onBtnClick: PropTypes.func,
  btnText: PropTypes.string,
};

Banner.defaultProps = {
  title: "",
  onBtnClick: undefined,
  btnText: "",
};
