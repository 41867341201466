import { tss } from 'tss-react';
import React from 'react';
import styles from "./styles";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import SliderForm from "../Shared/Slider/SliderForm";

import WebsaUITheme from '../Shared/WebsaUITheme';
import ThemeProvider from '../Shared/ThemeProvider';

import RailsForm from '../Shared/RailsForm';
import RailsRoutes from '../Shared/RailsRoutes';
import Copyright from "../Shared/Copyright/Copyright";

export default function RegistrationFormSuccess(props) {
  const theme = ThemeProvider(props.theme);
  const { classes, cx } = tss.create(styles(theme))();

  return (
    <WebsaUITheme theme={props.theme}>
      <div className={classes.root}>
        <div className={classes.slider}>
          <SliderForm/>
        </div>
        <div className={classes.form}>
          <div className={classes.head}>
            <p>
              Already have an account? <Link rel="stylesheet" href={RailsRoutes.new_user_session_path()}>Sign in</Link>
            </p>
          </div>
          <div className={cx(classes.flexColumn, classes.registrationSuccess)}>
            <span className="ico-document_verified"/>
            <span className="textStylesh2Default">
              Thank you!
              <br/>
              We'll contact you shortly
            </span>
            <span className="textStylesInfoBlockDescription">
              We’ll reach out soon to discuss your needs and how we can help.
            </span>
          </div>
          <Copyright/>
        </div>
      </div>
    </WebsaUITheme>
  );
}
