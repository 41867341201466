import colors from "../Shared/ThemeProvider/websa/colors";

export default function(theme) {
  return {
    root: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start"
    },
    loginForm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexFlow: "column",
      maxWidth: 450,
      width: "100%",
      "& h3": {
        textAlign: "center",
        margin: "0 0 49px"
      }
    },
    accountForm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexFlow: "column",
      maxWidth: 450,
      width: "100%",
      "& h3": {
        textAlign: "center",
        margin: "0 0 49px"
      },
      height: "580px"
    },
    regForm: {
      display: "flex",
      flexFlow: "column",
      maxWidth: 540,
      width: "100%",
      "& h3": {
        textAlign: "center",
        margin: "0 0 49px"
      }
    },
    pipeForm: {
      display: "flex",
      flexFlow: "column",
      maxWidth: 540,
      width: "100%",
      "& h3": {
        textAlign: "center",
        margin: "0 0 49px"
      }
    },
    slider: {
      "& .carousel": {
        display: "none"
      },
      '@media (min-width:1024px) and (orientation:landscape)': {
        width: "33.333vw",
        minWidth: "33.333vw",
        maxWidth: "33.333vw",
        height: "100%",
        "& .carousel": {
          display: "block"
        }
      }
    },
    long: {},
    autoWidth: {},
    tagForm: {
      width: 450,
      "&.long": {
        width: 540
      },
      "&$autoWidth": {
        width: "auto"
      }
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      height: "100%",
      width: "100%",
      padding: "0 81px",
      overflowX: "hidden",
      overflowY: "auto",
      scrollbarColor: "rgba(0, 0, 0, 0.18) transparent",
      "&::-webkit-scrollbar": {
        width: 12,
        height: 12,
        background: "none",
      },
      "&::-webkit-scrollbar-track": {
        margin: 9,
        background: "none",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 4,
      },
      "&::-webkit-scrollbar-thumb:vertical": {
        boxShadow: "inset -4px 0 0 0 #fff",
        background:
          "linear-gradient(to right, rgba(0,0,0,0.18) 0%,rgba(0,0,0,0.18) 66.666%,rgba(7,7,7,0) 66.666%,rgba(229,229,229,0) 100%)",
      },
      "&::-webkit-scrollbar-thumb:horizontal": {
        boxShadow: "inset 0 -4px 0 0 #fff",
        background:
          "linear-gradient(to bottom, rgba(0,0,0,0.18) 0%,rgba(0,0,0,0.18) 66.666%,rgba(7,7,7,0) 66.666%,rgba(229,229,229,0) 100%)",
      }
    },
    head: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      "& > p": {
        fontSize: 16,
        lineHeight: "18px",
        color: theme.colors.black54
      }
    },
    forgot: {
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: "13px",
      letterSpacing: 0.2,
      textAlign: "right",
      color: theme.palette.primary.main,
      marginBottom: 5,
      marginTop: -18
    },
    fullWidth: {
      width: "100%",
      marginTop: 18,
      marginBottom: 25
    },
    textField: {
      marginBottom: 27
    },
    errorBlockBig: {
      fontSize: 14,
      fontWeight: "normal",
      lineHeight: "18px",
      color: theme.colors.red90,
      borderRadius: 4,
      backgroundColor: theme.colors.red9,
      display: "inline-block",
      padding: "9px 18px",
      marginBottom: 27
    },
    errorBlock: {
      width: "100%",
      borderRadius: 4,
      backgroundColor: theme.colors.red9,
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: "13px",
      letterSpacing: 0.2,
      color: theme.colors.red90,
      display: "inline-block",
      textAlign: "center",
      padding: "7px 0",
      margin: "0 0 9px"
    },
    personal: {
      paddingTop: 27,
      width: 450
    },
    signature: {
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: "13px",
      letterSpacing: 0,
      paddingLeft: 18,
      color: theme.colors.black36,
      position: "relative",
      top: -24
    },
    tabSelected: {},
    tab: {
      display: "inline-flex",
      alignItems: "center",
      width: 207,
      "&$tabSelected": {
        "&:before": {
          color: theme.palette.primary.main
        }
      },
      "&:before": {
        position: "relative",
        marginRight: 9
      },
      "& span": {
        width: "auto"
      }
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "0 27px",
      "& > *[class*='ico-']": {
        width: 128,
        height: 128,
        lineHeight: "128px",
        marginBottom: 27,
        "&.ico-warning_info:before": {
          color: theme.colors.red90
        },
        "&.ico-document_verified:before": {
          backgroundImage: "linear-gradient(43deg, rgba(0, 146, 62, 0.90) 27%, rgba(0, 146, 62, 0.54) 100%)",
          color: 'transparent',
          backgroundClip: "text"
        },
        "&:before": {
          fontSize: 128,
          width: 128,
          height: 128,
          lineHeight: "128px",
          color: theme.colors.yellow90
        },
        "& + *": {
          marginBottom: 18
        }
      },
      "& > button": {
        margin: "24px 0 5px",
        "& + p": {
          margin: 0
        }
      }
    },
    registrationSuccess: {
      width: 396,
      textAlign: "center",
      "& > .textStylesh2Default": {
        fontWeight: 600
      }
    },
    otpContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: 324
    },
    otpInput: {
      width: 45,
      border: "none",
      boxShadow: `0 0 0 1px ${theme.colors.black9}`,
      borderRadius: 4,
      textAlign: "center",
      fontWeight: 500,
      color: theme.colors.black54,
      height: 54,
      marginBottom: 27,
      "&:focus": {
        boxShadow: `inset 0 0 0 2px ${theme.colors.blue54}`,
        color: theme.colors.blue90,
      },
      "&:nth-child(3)": {
        marginRight: 18
      }
    },
    otpError: {
      boxShadow: `inset 0 0 0 2px ${theme.colors.red54} !important`,
    },
    width324: {
      width: 324
    },
    buttonLinkContainer: {
      margin: "0 13.5px",
      "& > *": {
        minWidth: 170,
        paddingLeft: 27,
        paddingRight: 27,
        margin: "0 !important",
        // height: 36,
      }
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      "& $flexColumn": {
        padding: 0,
        textAlign: "left",
        alignItems: "flex-start"
      },
      "& a": {
        display: "flex",
        alignItems: "center",
        margin: "13px 0"
      },
      "& .ico-arrow_line": {
        display: "inline-block",
        transform: "rotate(-90deg)",
        width: 18,
        height: 18,
        lineHeight: "18px",
        marginLeft: 4,
        marginRight: 5,
        "&:before": {
          fontSize: 18,
          color: theme.palette.primary.main
        }
      }
    },
    error: {
      fontSize: 12,
      fontWeight: "normal",
      fontStyle: "normal",
      lineHeight: "13px",
      letterSpacing: 0,
      color: theme.colors.red90,
      margin: "-21px 0 24px",
      width: 324,
      boxSizing: "border-box",
      padding: "0 18px",
    },
    customHeight: {
      minHeight: 675,
    },
    forgotContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    banner: {
      padding: "13px 18px",
      margin: "18px 0",
      borderRadius: "4px",
      backgroundColor: colors.blue9,
    },
    bannerInner: {
      display: "flex",

      "& .ico-closed": {
        minWidth: 36,
        "&:before": {
          fontSize: 36,
          lineHeight: "36px",
          color: colors.blue90
        }
      }
    },
    bannerSeparator: {
      width: "2px",
      margin: "0 18px",
      backgroundColor: colors.blue90,
    },
    bannerColumn: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      fontSize: "14px",
      lineHeight: "18px",
      paddingRight: "6px" // content beautify
    },
    bannerTitle: {
      fontWeight: 500,
      color: colors.black90,
    },
    bannerMessage: {
      color: colors.black54,
    },
    stepIndex: {
      fontSize: 19,
      fontWeight: 600,
      lineHeight: "27px",
      textAlign: "center",
      color: theme.colors.black27,
      marginBottom: 7
    },
    stepTitle: {
      fontSize: 25,
      fontWeight: 600,
      lineHeight: "27px",
      textAlign: "center",
      color: theme.colors.black90,
      marginBottom: 13
    },
    stepActive: {
      "& > .MuiButton-text": {
        marginTop: -16,
        "& > .ico-back": {
          width: 27,
          height: 24
        }
      }
    },
    stepHidden: {
      display: "none"
    },
    inputBlockTitle: {
      color: theme.colors.black90,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "18px",
      marginBottom: 14,
      marginTop: 13
    }
  }
};
