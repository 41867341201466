import colors from "../colors";

export default {
  styles: {
    banner: {
      padding: "13px 18px",
      borderRadius: "4px",
      backgroundColor: colors.blue9,
    },
    bannerInner: {
      display: "flex",
    },
    bannerSeparator: {
      width: "2px",
      margin: "0 18px",
      backgroundColor: colors.blue90,
    },
    bannerColumn: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      fontSize: "14px",
      lineHeight: "18px",
    },
    bannerTitle: {
      fontWeight: 500,
      color: colors.black90,
    },
    bannerMessage: {
      color: colors.black54,
    },
  }
};
