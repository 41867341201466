import colors from "../colors";

export default {
  styles: {
    banner: {
      padding: "13px 18px",
      borderRadius: "4px",
      backgroundColor: colors.blue9,
    },
    bannerInner: {
      display: "flex",
    },
    separator: {
      width: "2px",
      margin: "0 18px",
      backgroundColor: colors.blue90,
    },
    column: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      fontSize: "14px",
      lineHeight: "18px",
    },
    title: {
      fontWeight: 500,
      color: colors.black90,
    },
    message: {
      color: colors.black54,
    },
    btnHolder: {
      display: "flex",
      justifyContent: "flex-end",
      margin: "9px -9px -5px 0",
    }
  }
};
